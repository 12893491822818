import React from "react"
import { Grid } from "theme-ui"

const CtaCardContainer = props => (
  <Grid
    columns={[1, 1, "7fr 5fr"]}
    gap={[4, null, null, 5]}
    mb={[4, null, 5]}
    {...props}
  >
    {props.children}
  </Grid>
)

export default CtaCardContainer
