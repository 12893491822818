import React from "react"
import { Box, Flex, Text, Button, Heading } from "theme-ui"
import CoverImage from "../cover_image"
import SbEditable from "storyblok-react"
import Link from "../link"

const ctaCard = props => {
  var imageSrcSet = "(min-width: 2000px) 1024px, (min-width: 768px) 50vw, 100vw"
  var quality

  if (props.cardtype === "navCard") {
    imageSrcSet = "(min-width: 768px) 312px,(min-width: 576px) 50vw, 100vw"
    quality = 30
  }
  return (
    <SbEditable content={props.blok}>
      <Box
        variant={props.cardtype || ""}
        sx={{
          overflow: "hidden",
          boxShadow: "card",
          "& > a": { width: "100%" },
          "& img": {
            transition: "1.5s",
          },
          "&:hover": {
            //opacity: "0",
            "& img": {
              transition: "1.5s",
              transform: "scale(1.033)",
            },
          },
        }}
      >
        <Link to={props.blok.link}>
          <Box
            variant="gradientFull"
            sx={{
              position: "relative",
              height: ["250px", "350px", "400px", null, "500px"],
              maxHeight: "500px",
              width: "100%",
            }}
            {...props}
          >
            <CoverImage
              srcsetSizes={imageSrcSet}
              image={props.blok.image}
              quality={quality}
              factor={props.factor}
            />

            <Flex
              sx={{
                position: "absolute",
                p: [3, 3, 4],
                top: "0",
                width: "100%",
                height: "100%",
                zIndex: "10",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <Box>
                {props.blok.pre_title && (
                  <Text
                    sx={{
                      color: "textContrast",
                      textTransform: "uppercase",
                      lineHeight: "1",
                      mb: 2,
                      fontWeight: "300",
                    }}
                  >
                    {props.blok.pre_title}
                  </Text>
                )}
                <Heading
                  as={props.cardtype === "navCard" ? "span" : "h3"}
                  sx={{
                    fontSize: ["5", "6", null, null, "7"],
                    color: "textContrast",
                    width: ["12ch"],
                    maxWidth: "12ch",
                  }}
                >
                  {props.blok.title || ""}
                </Heading>
              </Box>
              {props.blok.button_text ? (
                <Box>
                  <Button
                    variant="secondaryMedium"
                    sx={
                      {
                        //fontSize: [0, 0, 0, 1, 2],
                        // px: ["", "", "40px"],
                      }
                    }
                  >
                    {props.blok.button_text}
                  </Button>
                </Box>
              ) : null}
            </Flex>
          </Box>
        </Link>
      </Box>
    </SbEditable>
  )
}

export default ctaCard
