import React from "react"
import Components from "../components"
import { Hero } from "../hero"
import { Box } from "theme-ui"
import Section from "../section"
import { CardSlider } from "../slider"
import { CtaCard, CtaCardContainer } from "../cta_cards"
import { HeroCta } from "../hero"
import ReusableBlok from "../reusable_blok"

const Homepage = props => (
  <main>
    <Hero
      variant="herohome"
      image={props.blok.main_image}
      title_position_horizontal={props.blok.title_position_horizontal}
      title_position_vertical={props.blok.title_position_vertical}
    >
      {props.blok.hero_link && (
        <HeroCta
          blok={{
            hero_headline: props.blok.hero_headline,
            hero_link: props.blok.hero_link,
            hero_tagline: props.blok.hero_tagline,
            title_position_horizontal: props.blok.title_position_horizontal,
          }}
        />
      )}
    </Hero>
    <Section sx={{ background: "#000", pt: [4, null, 5] }}>
      <CtaCardContainer>
        {props.blok.cta_cards &&
          props.blok.cta_cards.map(blok =>
            React.createElement(CtaCard, {
              key: blok._uid,
              blok: blok,
            })
          )}
      </CtaCardContainer>
    </Section>
    <Box sx={{ mt: "5" }}>
      {props.blok.title_text &&
        props.blok.title_text.map(blok =>
          React.createElement(Components(blok.component), {
            key: blok._uid,
            blok: blok,
          })
        )}
    </Box>
    {props.blok.body &&
      props.blok.body.map(blok =>
        React.createElement(Components(blok.component), {
          key: blok._uid,
          blok: blok,
        })
      )}
    {props.blok.reusable_blok && props.blok.reusable_blok[0] && (
      <ReusableBlok blok={props.blok.reusable_blok[0].linked_bloks.content} />
    )}
    <CardSlider
      loop={props.blok.loop}
      title={props.blok.event_slider_title}
      link={props.blok.event_slider_link}
      slider_type="homepage"
    />
  </main>
)

export default Homepage
