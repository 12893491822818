import React from "react"
import { graphql } from "gatsby"
//import Components from "../components/components_stories"
import Homepage from "../components/routes/homepage"
import Header from "../components/header"
import Seo from "../components/seo"
import Footer from "../components/footer"
import { menuColor } from "./menu_color"
import { Flex } from "theme-ui"
import { LabelsProvider } from "../utils/labels_context"

class StoryblokEntry extends React.Component {
  static getDerivedStateFromProps(props, state) {
    if (state.story.uuid === props.pageContext.story.uuid) {
      return null
    }

    return StoryblokEntry.prepareStory(props)
  }

  static prepareStory(props) {
    const story = Object.assign({}, props.pageContext.story)
    const globalNavi = Object.assign({}, props.pageContext.globalNavi)
    const footer = Object.assign({}, props.pageContext.footer)
    const labelTranslations = Object.assign(
      {},
      props.pageContext.labelTranslations
    )
    story.content = JSON.parse(story.content)
    globalNavi.content = JSON.parse(globalNavi.content)
    footer.content = JSON.parse(footer.content)
    labelTranslations.content = JSON.parse(labelTranslations.content)

    return { story, globalNavi, footer, labelTranslations }
  }

  constructor(props) {
    super(props)

    this.state = StoryblokEntry.prepareStory(props)
  }

  render() {
    let content = this.state.story.content
    let globalNavi = this.state.globalNavi.content
    let footer = this.state.footer.content
    let langSwitch = this.state.story.translated_slugs
    content.lang =
      content.seo.lang =
      globalNavi.lang =
      langSwitch.currentLang =
        this.state.story.lang
    content.loop = this.props.data.allStoryblokEntry.edges

    return (
      <Flex sx={{ minHeight: "100vh", flexDirection: "column" }}>
        <LabelsProvider
          value={{
            lang: this.state.story.lang,
            labels: this.state.labelTranslations.content,
          }}
        >
          <Header
            blok={globalNavi}
            langs={langSwitch}
            menuColor={menuColor(content)}
          ></Header>
          <Homepage key={content._uid} blok={content} />
          <Footer blok={footer} langs={langSwitch} />
        </LabelsProvider>
      </Flex>
    )
  }
}

export default StoryblokEntry

export const Head = props => (
  <Seo meta={props.pageContext.story} lang={props.pageContext.lang} />
)

export const pageQuery = graphql`
  query (
    $lang: String!
    $category: String!
    $buildDate: Date!
    $sortOrder: [SortOrderEnum]
  ) {
    allStoryblokEntry(
      limit: 5
      sort: { fields: sort_by_date, order: $sortOrder }
      filter: {
        field_component: { eq: $category }
        lang: { eq: $lang }
        sort_by_date: { gte: $buildDate }
      }
    ) {
      edges {
        node {
          id
          name
          lang
          translated_slugs {
            lang
            path
          }
          content
          full_slug
          sort_by_date
          first_published_at
        }
      }
    }
  }
`
